$kolor: #FCDE51;
$kolor_text: #1a1a1a;

@import "google-fonts.css";
@import "./../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

html,
body {
  width: 100%;
  height: 100%;
  background-color: #ececec;
  font-family: 'Muli', sans-serif !important;
}
#root{
  display: grid;
  min-height: 100%;
  a, a:hover, a:active, a:visited{
    color: #000;
    text-decoration: none;
    font-weight: 500;
  }
  tr {
    background-color: #fff;
  }
  tr:nth-child(even) {
    background-color: #ededed;
  }
  thead tr {
    background-color: $kolor;
    color: $kolor_text;
  }
  main table.tab thead th {
    font-weight: bold;
  }
  tfoot th {
    background-color: $kolor;
    color: $kolor_text;
  }
  td {
    padding: 2px 4px;
    border: 1px solid #fff;
  }
  hr{
    margin: 2rem 0;
  }
  .pull-right{ float: right; }
  .pull-left{ float: left; }
  .center{ text-align: center; }
  .left{ text-align: left; }
  .right{ text-align: right; }
  table.transparent{
    tr{
      background-color: transparent;
      td{
        border: 0 none;
        text-align: left;
      }
    }
  }
  header {
    height: 60px;
    width: 100%;
    position: relative;
    margin: 0 auto 15px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav{ height: 100%; }
    button.logout{
      background-color: #9fa2b4;
      display: block;
      height: 100%;
      padding: 5px 40px;
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
      color: #fff;
      border: none;
      cursor: pointer;
      svg{
        fill: #fff;
        margin-right: 10px;
        vertical-align: sub;
      }
      &:hover{
        background-color: $kolor;
        color: $kolor_text;
        svg{ fill: $kolor_text; }
      }
    }
    .auth_info{
      font-size: 13px;
      font-weight: 400;
      line-height: 60px;
      color: #373f51;
      >span{
        padding-right: 60px;
        padding-left: 22px;
      }
    }
    .nav{
      align-items: flex-end;
    }
    .logo{
      width: 170px;
      height: 57px;
      margin-left: 18px;
      background: url(../assets/logotype.png) no-repeat center left / contain #ffffff;
    }
  }
  article {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
  }
  main{
    font-size: 16px;
    padding: 10px 15px;
    h1{
      font-size: 18px;
      font-weight: 700;
    }
    .order_header{
      border-bottom: 1px solid #e8e8e8;
      padding-bottom: 5px;
      margin-bottom: 25px;
    }
    h2{
      font-size: 24px;
      font-weight: 300;
      line-height: 45px;
      margin-bottom: 0;
      .subheader{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
    h3{
      text-align: center;
      margin-bottom: 25px;
    }
    h4{
      color: #000;
      font-size: 21px;
      font-weight: 800;
      padding-bottom: 20px;
      margin-bottom: 10px;
      margin-top: 15px;
      &.underline{
        border-bottom: 1px solid #e8e8e8;
      }
    }
    input{
      border-radius: 4px;
      border: solid 1px #d2d2d9;
    }
    table.tab{
      width: 100%;
      border-bottom: 2px solid #888;
      th{
        padding: 2px 8px;
        font-weight: normal;
      }
      td{
        padding: 2px 8px;
        border: none;
      }
    }
    button:not(.react-datepicker__navigation):not(.filepond--file-action-button), .button, input[type="submit"] {
      background-color: $kolor;
      color: $kolor_text;
      cursor: pointer;
      border: 0 none;
      border-radius: 0.25rem;
      line-height: 1;
      padding: 5px 20px;
      min-width: 260px;
      height: 46px;
      font-size: 15px;
      font-weight: 800;
      &.secondary{
        background-color: #ccc;
        color: #666;
      }
      &[disabled]{
        background-color: #ddd;
        color: #aaa;
      }
      &.btn-normal{
        font-size: 18px;
      }
      &.btn-sm{
        font-size: 16px;
        padding: 0.6em 1.2em;
      }
    }
    .center_wrapper{
      display: grid;
      height: 100%;
      justify-content: center;
      align-content: center;
    }
    .buttons_wrap{
      margin-bottom: 30px;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      button + button{
        margin-left: 30px;
      }
    }
    .ramka{
      background-color: #fff;
      padding: 20px 35px;
      margin-bottom: 40px;
      margin-top: 10px;
      border-radius: 6px;
      box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
      >h4{
        padding: 6px 15px 15px;
      }
      label, .label{
        margin-bottom: 0;
        color: #878a99;
        font-size: 16px;
        font-weight: 400;
        padding-right: 3px;
      }
      .buttons_wrap{
        margin-bottom: 20px;
      }
    }
    .react-autosuggest__container {
      display: inline-block;
      width: 100%;
      position: relative;
      margin: 50px 0 20px;
      .react-autosuggest__suggestions-container {
        position: absolute;
        width: 100%;
        background-color: #fff;
        top: 100%;
        border: 1px solid #A9A9A9;
        opacity: 0;
        ul.react-autosuggest__suggestions-list {
          list-style: none;
          text-align: left;
          margin: 5px 0;
          padding-left: 0;
          li{
            padding-left: 0.8em;
            cursor: pointer;
            &:hover{
              background-color: #E0E0E0;
            }
          }
        }
      }
      .react-autosuggest__suggestions-container--open{
        opacity: 1;
      }
    }
    .searchBox{
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      padding: 0.8em;
      padding-left: 3.5em;
      width: 100%;
      background: url('../assets/barcode.png') 20px center no-repeat #fff;
    }
    .breadcrumb{
      padding: 0;
      display: block;
      margin-bottom: 1.5rem;
    }
    .field {
      position: relative;
      margin-top: 20px;
      label {
        font-weight: 700;
        display: block;
        position: absolute;
        top: 14px;
        left: 15px;
        color: #9fa2b4;
        background-color: transparent;
        cursor: text;
        font-size: 14px;
        transition: all .15s ease-in-out 0s;
      }
      input{
        height: 48px;
        font-size: 15px;
        line-height: 24px;
        transition: all .15s ease-in-out 0s;
        width: 100%;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
        font-weight: 600;
        &:not(:placeholder-shown), &:focus {
          background-color: #FFFFFF;
          border-color: #9fa2b4;
          outline: 0;
          box-shadow: none;
          + label{
            top: -9px;
            font-size: 12px;
            background-color: #fff;
            color: #878a99;
            cursor: pointer;
            padding: 0 5px;
            border-radius: 5px;
            margin-left: -5px;
            font-weight: 400;
          }
        }
        &:focus{
          border-width: 2px;
          border-color: #9fa2b4;
          box-shadow: none;
          + label{
            font-weight: 700;
          }
        }
      }
      input:-internal-autofill-selected{
          background-color: #fff;
      }
    }
    .login_form{
      min-width: 350px;
      border-radius: 6px;
      background: #ffffff;
      box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
      padding: 20px 45px 25px;
      form{
        position: relative;
        h2{
          font-size: 24px;
          color: #666666;
          margin: 0 0 30px;
          padding-top: 15px;
          text-align: center;
          font-weight: 300;
          letter-spacing: -0.5px;
          padding-bottom: 1.5rem;
          margin-bottom: 2rem;
          border-bottom: 1px solid #dee2e6;
        }
      }
      input[type="submit"]{
        display: block;
        width: 100%;
        margin-top: 40px;
      }
      .login-failed {
        color: #e11;
        font-weight: 600;
        text-align: center;
        padding: 1rem 0 0;
        font-size: 14px;
      }
    }

    .elem-list{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 30px;
      .elem-tile{
        color: #151408;
        text-decoration: none;
        font-size: 14px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #fff;
        padding: 15px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        font-weight: 400;
        &:hover{
          border-color: $kolor;
        }
        &.inactive{
          background-color: #f18b8b;
          color: #ffffff;
        }
        &[data-important="1"]{
          background-color: #ff5a5a !important;
          color: #fff !important;
        }
        h5{
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 1rem;
        }
        table{
          width: 100%;
          tr:nth-child(even){ background-color: transparent; }
          th{
            color: #878a99;
            font-size: 14px;
            font-weight: 400;
            img{
              margin-right: 8px;
              vertical-align: unset;
            }
          }
          td{
            text-align: right;
          }
        }
        .elem-footer{
          font-size: 18px;
          padding: 2px 15px;
          background-color: #808080;
          color: #fff;
          overflow: hidden;
          margin: 10px -15px 0;
        }
      }
    }
    .elem-view{
      background-color: #fff;
      padding: 15px 20px;
      margin: 30px 0;
      label{
        color: #878A99;
        margin-bottom: 0;
      }
    }
    .order_lines{
      img{
        width: 128px;
        height: 128px;
        object-fit: contain;
      }
    }
    #myMap{
      min-height: 400px;
    }
    .no-messages{ font-style: italic; text-align: center; }
    .messages{
      background-color: #fff;
      padding: 15px 0 1px;
      margin: 10px 0;
      font-size: 20px;
      .message{
        display: flex;
        margin-bottom: 20px;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        >div{
          padding: 15px 20px;
        }
        .message-avatar-container{
          border-right: 1px solid #eaeaea;
          min-width: 100px;
          .message-avatar{
            width: 64px;
            height: 64px;
            background-color: #fff;
            border-radius: 50%;
            border: 2px solid #eaeaea;
            margin: 0 auto 5px;
          }
        }
        .message-content{
          padding-top: 5px;
          .message-meta{
            color: #a0a0a0;
            font-size: smaller;
            margin-bottom: 10px
          }
        }
        [data-oe-id="task_links"]{
          display: none;
        }
        .message-body{
          word-break: break-word;
        }
      }
    }
    button.message-composer-button {
      font-weight: normal;
      margin-top: 20px;
    }
    .message-composer{
      margin-top: 20px;
      background-color: #fff;
      .rdw-editor-toolbar{
        margin-bottom: 0;
      }
      .rdw-editor-main{
        border-bottom:1px solid #F1F1F1;
        padding: 0 15px;
        min-height: 200px;
      }
      .composer-footer{
        text-align: right;
        .composer-send{
          margin: 10px 20px;
        }
      }
      .public-DraftEditor-content {
        word-break: break-word;
      }
    }
    .historyWrap{
      padding: 0 5px;
    }
    .f_group{
      display: grid;
      grid-template-columns: minmax(max-content, 45%) minmax(min-content, 1fr);
      grid-template-areas: "labels data";
      padding: 8px 0;
      line-height: 17px;
      position: relative;
      label{
        grid-area: labels;
        padding-left: 32px;
        position: relative;
        &:before{
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: left 0;
          opacity: 0.4;
        }
      }
      >div{
        grid-area: data;
        word-break: break-word;
      }
      svg.worldIcon {
        position: absolute;
        left: 0;
        fill: #9fa2b4;
      }
      &.carrier label:before{ background-image: url(../assets/icons/carrier.png); }
      &.status_date label:before{ background-image: url(../assets/icons/date.png); }
      &.status label:before, &.waybill label:before{ background-image: url(../assets/icons/pack.png); }
      &.delivery label:before{ background-image: url(../assets/icons/location.png); }
      &.invoice_vat label:before{ background-image: url(../assets/icons/invoice.png); }
      &.delivery_name label:before, &.invoice_name label:before{ background-image: url(../assets/icons/person.png); }
      &.qty label:before{ background-image: url(../assets/icons/qty.png); }
      &.price label:before{ background-image: url(../assets/icons/price.png); }
    }
    .revealName{
      margin-top: 5px;
      padding: 2px 15px !important;
      height: 34px !important;
      min-width: 0 !important;
    }
  }
  #status_bar{
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .status_bar__step{
      background-color: transparent;
      color: #000;
      min-height: 40px;
      font-size: 0.9rem;
      font-weight: 400;
      box-shadow: none;
      border: none;
      color: #999;
      position: relative;
      text-align: center;
      padding: 25px 10px 0;
      transition: color ease-in-out .2s;
      min-width: 0;
      &:before{
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        top: 12px;
        left: 0;
        background-color: #d8d8d8;
        border: none;
      }
      &:first-child:before {
        width: 50%;
        left: auto;
        right: 0;
      }
      &:last-child:before {
        width: 50%;
      }
      &:after{
        content: "";
        display: block;
        width: 22px;
        height: 22px;
        position: absolute;
        top: 2px;
        left: 50%;
        transform: translate(-50%);
        border: 2px solid #d8d8d8;
        border-radius: 50%;
        background-color: #fff;
        box-sizing: border-box;
        margin: 0;
      }
      &.active{
        font-weight: bold;
        &:after{
          border: 0 none;
          color: $kolor_text;
          background: url(../assets/icons/status_check.png) no-repeat $kolor;
          background-position: center;
          @if (lightness($kolor) < 50) {
            filter: invert(1);
          }
        }
      }
    }
  }
  footer{
    font-weight: 400;
    font-size: 0.8rem;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $kolor_text;
    background-color: $kolor;
    padding: 0 30px;
    p{
      margin: 0;
    }
    select.lang{
      background-color: transparent;
      border: none;
      font-weight: 300;
      padding-right: 15px;
      margin-right: -15px;
      background: transparent;
      -webkit-appearance: none;
      option{ color: #000; }
    }
    .selectArr, .worldIcon{
      fill: $kolor_text;
      pointer-events: none;
    }
    .worldIcon{
      margin-right: 8px;
      vertical-align: sub;
      opacity: 0.7;
    }
    .opacity-3{ opacity: 0.4; }
  }

  button.highlighted {
    background-color: #f00 !important;
    color: #fff !important;
    border: 2px solid #000 !important;
  }

  a.rma{
    min-width: 260px;
    height: 46px;
    display: inline-block;
    vertical-align: bottom;
    line-height: 36px;
    text-align: center;
  }
  button + .rma{
      margin-left: 40px;
  }

  /* ================ Spinner =================*/
  @import "spinner.css";
  /* ================ Spinner =================*/
}
/* ================ Popup =================*/
@import "popup.scss";
/* ================ Popup =================*/
/* ============== Responsive ==============*/
@media (max-width: 1200px) {
  #root main .f_group{
    display: block;
    label + div{ padding-left: 32px; margin-top: 5px; }
  }
}
@media (max-width: 900px) {
  #root #status_bar .status_bar__step{
    font-size: 0.7rem
  }
  #popupContainer .mm-popup__box__body{
    white-space: unset;
  }
}
@media (max-width: 767px) {
  #root main{
    padding: 10px 0;
  }
  .mobile-prices{
    line-height: 15px;
    padding-bottom: 10px !important;
  }
  #root #status_bar .status_bar__step{
    font-size: 0.7rem;
    padding: 25px 4px 0;
    max-width: 70px;
  }
  #root main .order_header{
    flex-direction: column-reverse;
    #status_bar{
      justify-content: start;
      margin-bottom: 20px;
    }
  }
  #root main #myMap{
    margin: 30px 0;
  }
  footer .container{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 1.2;
    .pull-right{ margin-bottom: 5px !important; }
  }
}
@media (max-width: 420px){
  #popupContainer {
    .mm-popup__box{
      transform: none;
      max-width: 100%;
    }
    .mm-popup__box__footer__right-space .mm-popup__btn{
      margin-left: 0;
    }
  }
}
/* ============== Responsive ==============*/
