$kolor: #FCDE51;

.contrast_switcher{
  border: 0 none;
  background: transparent;
  margin: 0 -10px 0 15px;
  opacity: 0.7;
  &:focus{ outline: none; }
  &:hover{ opacity: 1; }
}
html.high_contrast{
  background-color: #000;
  body{ background-color: #000; }
  #root{
    header{
      background-color: #000;
      border-bottom: 1px solid #4e4e4e;
      .auth_info{ color: #fff; }
      button.logout{
        background-color: $kolor;
        color: #000;
        svg{ fill: #000; }
      }
      .logo{ background-color: #000; }
    }
    .breadcrumb{ background-color: #000; color: $kolor; }
    #status_bar .status_bar__step{
      &:before {
        background-color: #000
      }
      &:after {
        border-color: #000;
      }
      color: #000;
    }
    main{
      h2{ font-weight: 600; }
      .login_form form h2{
        color: #000;
        border-bottom-color: #000;
      }
      .f_group label::before { opacity: 1; }
      .center_wrapper h2{
        font-weight: 600;
        color: #fff;
      }
      .elem-view {
        label, .label{
          color: #000;
          font-weight: 600;
        }
      }
      .f_group svg.worldIcon{
        fill: #000;
      }
    }
    footer{
      color: #000;
      .worldIcon, .contrast_switcher{ opacity: 1; }
      .selectArr path{ fill-opacity: 1 !important; }
      select.lang{ font-weight: 400; }
    }
  }

  thead tr,
  #root main button:not(.react-datepicker__navigation):not(.filepond--file-action-button),
  #root main .button,
  #root main input[type="submit"],
  #popupContainer .mm-popup button,
  #root footer,
  #root thead tr,
  #root tfoot th,
  #popupContainer .mm-popup__box__header,
  #root footer a, #root footer a:hover, #root footer a:active, #root footer a:visited,
  #root #status_bar .status_bar__step.active:after
  {
    background-color: #fcde51;
    color: #000 !important;
  }
  #root footer select.lang
  {
    color: #000 !important;
  }
  #root footer svg{
    fill: #000 !important;
  }
}
